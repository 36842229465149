import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import moment from "moment";
import { Helmet } from "react-helmet-async";
import { Loader } from "../../../Utils/Loader";
import { Col, Row, Button, Form } from "react-bootstrap";
import { Formik, Form as FormikForm, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { Link, useNavigate } from "react-router-dom";
import {
  GetPaypalKey,
  GetCreditPaginate,
  AddTransferCredit,
} from "../../../Services/Api/payment";
import { PayPalScriptProvider } from "@paypal/react-paypal-js";
import {
  CurrencyConverter,
  AmountFormater,
} from "../../../Utils/CommanFunctions";
import { GetAllSetting } from "../../../Services/Api/comman";
import { ErrorToast, SuccessToast } from "../../../Utils/SweetAlert";
import CheckoutForm from "./CheckoutForm";
import { UserFooter } from "../Layout";
import Breadcrumb from "react-bootstrap/Breadcrumb";
import PaginationSimple from "../../../Utils/PaginationSimple";

const PaypalCredit = (props) => {
  const navigate = useNavigate();
  const { userDetails } = props;
  const userData = userDetails;
  const [loadingComponent, setLoadingComponent] = useState(false);
  const [page, setPage] = useState(0);
  const [totalRecords, setTotalRecords] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [creditHistory, setCreditHistory] = useState({});
  const [amount, setAmount] = useState("");
  const [creditPPC, setCreditPPC] = useState("");
  const [showAmount, setShowAmount] = useState(false);
  const [clientId, setClientId] = useState("");
  const [initialOptions, setInitialOptions] = useState({
    clientId: "",
    currency: "USD",
    intent: "capture",
    "data-order-id": "",
  });
  const [clientSecret, setClientSecret] = useState("");
  const limit = 10;

  const handleSelectCredit = (credit) => {
    if (typeof credit === "number" && !isNaN(credit)) {
      setCreditPPC(credit);
      setAmount(credit / 10);
    } else {
      setCreditPPC("");
      setAmount("");
    }
  };

  const signInSchema = Yup.object().shape({
    credit: Yup.number()
      .min(7.5, "Credit cannot be less than 7.5")
      .max(100000, "Credit cannot exceed 100000")
      .required("Credit must be number"),
    email: Yup.string().email().required("Email is required"),
    remarks: Yup.string().required('Please provide your remarks'),
  });

  const GetExerciseList = async (limit = "", page = "") => {
    setIsLoading(true);
    await GetCreditPaginate({ limit: limit, page: page })
      .then((res) => {
        setIsLoading(false);
        if (res?.data?.data?.data?.length) {
          setCreditHistory(res?.data?.data?.data);
          setTotalRecords(res?.data?.data?.paginate?.total);
        } else {
          setCreditHistory([]);
          setTotalRecords(0);
        }
      })
      .catch((err) => {
        setCreditHistory([]);
        setTotalRecords(0);
        setIsLoading(false);
      });
  };

  const handlePageChange = async (e, pageNew) => {
    setPage(pageNew);
  };

  const getAllSetting = async () => {
    setLoadingComponent(true);
    await GetAllSetting()
      .then((res) => {
        setLoadingComponent(false);
        if (res?.data?.data?.details) {
          let resClientId = res?.data?.data?.details?.client_id;
          setClientId(resClientId);
        } else {
          setClientId("");
        }
      })
      .catch((err) => {
        setLoadingComponent(false);
        setClientId("");
        if (typeof err.response.data.message !== "undefined") {
          ErrorToast(err.response.data.message || "Server Error!!");
        } else {
          ErrorToast(err?.message || "Server Error!!");
        }
      });
  };

  const getPaypalKey = async (data) => {
    setLoadingComponent(true);
    await GetPaypalKey(data)
      .then((res) => {
        setLoadingComponent(false);
        setClientSecret("");
        if (res?.data?.data) {
          let resOrderId = res?.data?.data?.order_id;
          if (clientId) {
            setInitialOptions({
              ...initialOptions,
              clientId: clientId,
              "data-order-id": resOrderId,
            });
            setClientSecret(resOrderId);
          }
        }
      })
      .catch((err) => {
        setLoadingComponent(false);
        setClientSecret("");
        if (typeof err.response.data.message !== "undefined") {
          ErrorToast(err.response.data.message || "Server Error!!");
        } else {
          ErrorToast(err?.message || "Server Error!!");
        }
      });
  };

  const handleAddCredit = (creditPPC) => {
    if (creditPPC >= 100 && creditPPC <= 100000) {
      setShowAmount(true);
    } else {
      setShowAmount(false);
      if (creditPPC > 100000) {
        ErrorToast("Error!! Credit can contain a maximum of 100000");
      } else {
        ErrorToast("Error!! Please enter atleast 100 credit");
      }
    }
  };

  const handleSubmit = (amount) => {
    if (amount >= 1) {
      getPaypalKey({ amount: amount });
    } else {
      setShowAmount(false);
      ErrorToast("Error!! Please enter atleast 100 credit");
    }
  };

  const handleDetails = (history) => {
    let tdDetails = "";

    if (history?.is_transfered) {
      if (history?.action === 'credit') {
        tdDetails = (
          <>
            <span>Transfer From : </span>
            <br />
            {history?.transfer_from_user?.name || ""}{" "}
            {history?.transfer_from_user?.email || ""}
            <br />
            {history?.transfer_from_user?.remarks || ""}
          </>
        );
      } else {
        tdDetails = (
          <>
            <span>Transfer To : </span>
            <br />
            {history?.transfer_to_user?.name || ""}{" "}
            {history?.transfer_to_user?.email || ""}
            <br />
            {history?.transfer_from_user?.remarks || ""}
          </>
        );
      }
    } else {
      if (history?.action === "deduct") {
        tdDetails = history?.subscription_id ? (
          <>
            <span>Word Pulse Membership Activated</span>
          </>
        ) : (
          <>
            <span>Product : </span>
            <br />
            {history?.product?.name || ""}
          </>
        );
      } else {
        tdDetails = (
          <>
            <span>Purchased</span>
            <br />
            Transaction ID: {history?.transaction_id || ""}
          </>
        );
      }
    }

    return tdDetails;
  };

  useEffect(() => {
    getAllSetting();
  }, []);

  useEffect(() => {
    GetExerciseList(limit, page);
  }, [limit, page]);

  return !loadingComponent ? (
    <>
      <Helmet>
        <title>Credits | PhrasePulse</title>
      </Helmet>
      <div className="page-wrapper">
        <div className="content">
          <div className="page-header">
            <div className="row align-items-center">
              <div className="col">
                <h3 className="page-title">Phrase Pulse Credits</h3>
                <Breadcrumb>
                  <Breadcrumb.Item>
                    <span onClick={() => navigate("/user/dashboard")}>
                      Dashboard
                    </span>
                  </Breadcrumb.Item>
                  <Breadcrumb.Item active>Credits</Breadcrumb.Item>
                </Breadcrumb>
              </div>
            </div>
          </div>
          <Row>
            {!showAmount && !clientSecret && (
              <Col lg={12} md={12} xs={12}>
                <div className="card">
                  <div className="card-header">
                    <h4 className="card-title">
                      Add PPC{" "}
                      <small className="text-danger small">
                        (EX: 100 PPC = $10.00 USD)
                      </small>
                    </h4>
                  </div>
                  <div className="card-body">
                    <div className="row">
                      <div className="col-lg-12">
                        <div className="credit-amount">
                          <div className="credit-amount-list">
                            <Button
                              variant="outline-primary"
                              size="lg"
                              className="w-100"
                              onClick={() => handleSelectCredit(200)}
                            >
                              200
                            </Button>
                          </div>

                          <div className="credit-amount-list">
                            <Button
                              variant="outline-primary"
                              size="lg"
                              className="w-100"
                              onClick={() => handleSelectCredit(300)}
                            >
                              300
                            </Button>
                          </div>

                          <div className="credit-amount-list">
                            <Button
                              variant="outline-primary"
                              size="lg"
                              className="w-100"
                              onClick={() => handleSelectCredit(400)}
                            >
                              400
                            </Button>
                          </div>

                          <div className="credit-amount-list">
                            <Button
                              variant="outline-primary"
                              size="lg"
                              className="w-100"
                              onClick={() => handleSelectCredit(500)}
                            >
                              500
                            </Button>
                          </div>

                          <div className="credit-amount-list">
                            <Button
                              variant="outline-primary"
                              size="lg"
                              className="w-100"
                              onClick={() => handleSelectCredit(1000)}
                            >
                              1000
                            </Button>
                          </div>

                          <div className="credit-amount-list">
                            <Button
                              variant="outline-primary"
                              size="lg"
                              className="w-100"
                              onClick={() => handleSelectCredit(2000)}
                            >
                              2000
                            </Button>
                          </div>
                        </div>
                      </div>

                      <div className="col-lg-12">
                        <div className="credit-field">
                          <Form.Control
                            type="number"
                            id="amount"
                            className="text-center"
                            placeholder="500"
                            title={`Enter PPC`}
                            value={creditPPC}
                            min={100}
                            max={10000}
                            onChange={(e) => {
                              const inputValue = parseFloat(e.target.value);
                              handleSelectCredit(inputValue);
                            }}
                          />
                          <Form.Text muted className="text-center">
                            You can enter your self... (minimum 100 PPC - 10$)
                          </Form.Text>
                          <Col className="mt-3 d-flex justify-content-center">
                            <Button
                              variant="primary"
                              size="lg"
                              className="w-50 btnstl"
                              onClick={() => handleAddCredit(creditPPC)}
                            >
                              Add PPC
                            </Button>
                          </Col>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </Col>
            )}

            {showAmount && clientSecret && (
              <Col lg={12} md={12} xs={12}>
                <div className="card">
                  <div className="card-header">
                    <h4 className="card-title">
                      Your Payment is in Process (
                      {CurrencyConverter(initialOptions?.currency)}
                      {""}
                      {AmountFormater(amount)})
                    </h4>
                  </div>
                  <div className="card-body">
                    <PayPalScriptProvider options={initialOptions}>
                      <Row>
                        <Col lg={6} md={12} xs={12}>
                          <h5 className="mb-5">
                            We're excited to inform you that your payment is
                            currently in process through PayPal, our trusted
                            payment gateway partner. Thank you for choosing us
                            for your transaction needs!
                          </h5>
                          <CheckoutForm
                            amount={amount}
                            initialOptions={initialOptions}
                          />
                        </Col>
                        <Col lg={6} md={12} xs={12}>
                          <p>
                            If you have any questions or concerns regarding your
                            payment or if you encounter any issues during the
                            process, please don't hesitate to reach out to our{" "}
                            <Link to="/user/support" target="_blank">
                              customer support
                            </Link>{" "}
                            team. We're here to assist you every step of the way
                            and ensure a smooth and seamless experience.
                          </p>
                          <p>
                            Once the payment is processed, it will undergo a
                            verification process to ensure its authenticity and
                            security. This typically takes a few moments but may
                            vary depending on the payment method used.
                          </p>
                        </Col>
                      </Row>
                    </PayPalScriptProvider>
                  </div>
                </div>
              </Col>
            )}

            {showAmount && !clientSecret && (
              <Col lg={12} md={12} xs={12}>
                <div className="card">
                  <Row>
                    <Col lg={8} md={6} xs={12}>
                      <div className="card-body">
                        <div className="available-balance">
                          <h4>Available PPC Balance</h4>
                          <h2 className="balance">
                            {userData?.user?.credit || 0}
                          </h2>
                          <p>
                            Since you have enough money in your PhrasePulse
                            account.
                          </p>
                        </div>
                      </div>
                    </Col>

                    <Col lg={4} md={6} xs={12}>
                      <div className="card mb-0">
                        <div className="card-header">
                          <h4 className="card-title">Buy {creditPPC} PPC</h4>
                        </div>
                        <div className="card-body">
                          <div className="w-100">
                            <div className="d-flex total-amount">
                              <p>Subtotal</p>
                              <p className="ms-auto">
                                {CurrencyConverter(initialOptions?.currency)}
                                {""}
                                {AmountFormater(amount)}
                              </p>
                            </div>
                            <div className="d-flex total-amount">
                              <p>Estimated taxes</p>
                              <p className="ms-auto">
                                {CurrencyConverter(initialOptions?.currency)}00
                              </p>
                            </div>
                            <div className="d-flex total-amount">
                              <p>Estimated Total</p>
                              <p className="ms-auto">
                                <strong>
                                  {CurrencyConverter(initialOptions?.currency)}
                                  {""}
                                  {AmountFormater(amount)}
                                </strong>
                              </p>
                            </div>
                            <p className="pt-2">
                              Learn about{" "}
                              <Link to="/refund-policy" target="_blank">
                                Privacy Policy
                              </Link>
                            </p>
                          </div>
                          <Button
                            variant="primary"
                            size="lg"
                            className="w-100 mt-3 btnstl"
                            onClick={() => handleSubmit(amount)}
                          >
                            Buy Now
                          </Button>
                        </div>
                      </div>
                    </Col>
                  </Row>
                </div>
              </Col>
            )}

            {!showAmount && creditHistory?.length > 0 && (
              <Col lg={12} md={12} xs={12}>
                <div className="card card-table">
                  <div className="card-header">
                    <h4 className="card-title">Credit History</h4>
                  </div>
                  <div className="card-body pb-3">
                    <div className="table-responsive custom-pagination">
                      <table className="table table-center table<img<image-hover datatable">
                        <thead className="thead-light">
                          <tr>
                            <th>Date</th>
                            <th>Credit</th>
                            <th>Action</th>
                            <th>Details</th>
                          </tr>
                        </thead>
                        <tbody>
                          {!isLoading && (
                            <>
                              {creditHistory?.map((history, index) => (
                                <tr key={index}>
                                  <td>
                                    {moment(history?.createdOn).format(
                                      "YYYY-MM-DD hh:mm A"
                                    ) || ""}
                                  </td>
                                  <td>{history?.credit || 0}</td>
                                  <td>
                                    {history?.action === "credit" ? (
                                      <button
                                        className="btn btn-success btn-sm"
                                        type="button"
                                      >
                                        {history?.action}
                                      </button>
                                    ) : (
                                      <button
                                        className="btn btn-danger btn-sm"
                                        type="button"
                                      >
                                        {history?.action}{" "}
                                      </button>
                                    )}
                                  </td>
                                  <td>{handleDetails(history)}</td>
                                </tr>
                              ))}
                              {creditHistory?.length === 0 && (
                                <tr>
                                  <td colSpan={4}>No Records Found.</td>
                                </tr>
                              )}
                            </>
                          )}
                          {isLoading && (
                            <tr>
                              <td align="center" colSpan={4}>
                                Loading...
                              </td>
                            </tr>
                          )}
                        </tbody>
                      </table>
                    </div>
                    <div className="d-flex justify-content-center">
                      <PaginationSimple
                        totalItem={totalRecords}
                        itemsPerPage={limit}
                        page={page}
                        handleChangePage={handlePageChange}
                      />
                    </div>
                  </div>
                </div>
              </Col>
            )}

            {!showAmount && userData?.user?.credit > 0 && (
              <Col lg={12} md={12} xs={12}>
                <div className="card mb-0">
                  <div className="card-header">
                    <h4 className="card-title">
                      Transfer Credit to Your Friend
                    </h4>
                  </div>
                  <div className="card-body">
                    <Formik
                      enableReinitialize
                      initialValues={{ email: "", credit: "", remarks: "" }}
                      validationSchema={signInSchema}
                      validateOnChange
                      onSubmit={async (values, { setSubmitting }) => {
                        const postData = {
                          credit: values.credit,
                          email: values.email,
                          remarks: values.remarks,
                        };
                        await AddTransferCredit(postData)
                          .then((res) => {
                            SuccessToast(
                              res?.data?.message ||
                              "Credit Transfer successfully."
                            );
                            setSubmitting(false);
                            window.location.reload();
                          })
                          .catch((err) => {
                            if (
                              typeof err.response.data.message !== "undefined"
                            ) {
                              ErrorToast(
                                err.response.data.message || "Server Error!!"
                              );
                            } else {
                              ErrorToast(err?.message || "Server Error!!");
                            }
                            setSubmitting(false);
                          });
                      }}
                    >
                      {(formik) => {
                        const { errors, touched, isSubmitting } = formik;
                        return (
                          <FormikForm>
                            <div className="row">
                              <div className="col-md-6">
                                <div className="form-group">
                                  <label>
                                    Credit{" "}
                                    <span className="text-danger">*</span>
                                  </label>
                                  <Field
                                    type="text"
                                    className={`form-control ${errors.credit && touched.credit
                                      ? "input-error"
                                      : null
                                      }`}
                                    name="credit"
                                    placeholder="Enter Credit"
                                  />
                                  <ErrorMessage
                                    name="credit"
                                    component="span"
                                    className="error"
                                  />
                                </div>
                                <div className="form-group">
                                  <label>
                                    Enter your friend email{" "}
                                    <span className="text-danger">*</span>
                                  </label>
                                  <Field
                                    type="text"
                                    className={`form-control ${errors.email && touched.email
                                      ? "input-error"
                                      : null
                                      }`}
                                    name="email"
                                    placeholder="Enter your friend email"
                                  />
                                  <ErrorMessage
                                    name="email"
                                    component="span"
                                    className="error"
                                  />
                                </div>
                                <div className="form-group">
                                  <label>
                                    Remarks {" "}
                                    <span className="text-danger">*</span>
                                  </label>
                                  <Field
                                    as="textarea"
                                    className={`form-control ${errors.remarks && touched.remarks
                                      ? "input-error"
                                      : null
                                      }`}
                                    name="remarks"
                                    placeholder="Enter your remarks"
                                  />
                                  <ErrorMessage
                                    name="remarks"
                                    component="span"
                                    className="error"
                                  />
                                </div>
                              </div>
                              <div className="col-md-6">
                                <h3>
                                  Convenient Credit Transfer to Your Friend
                                </h3>
                                <p>
                                  Introducing a hassle-free way to share credit
                                  with your friends! Now you can easily transfer
                                  credit from your account to your friend's
                                  account with just a few taps, ensuring they
                                  never run out of talk time or data when they
                                  need it most.
                                </p>
                              </div>
                            </div>
                            <div className="col-lg-12">
                              <button
                                type="submit"
                                className="btn btn-primary btnstl"
                                disabled={isSubmitting}
                              >
                                Transfer Now
                                {isSubmitting && (
                                  <i className="fas fa-spinner fa-spin"></i>
                                )}
                              </button>
                            </div>
                          </FormikForm>
                        );
                      }}
                    </Formik>
                  </div>
                </div>
              </Col>
            )}
          </Row>
        </div>
        <UserFooter />
      </div>
    </>
  ) : (
    <>
      <Loader loading={loadingComponent} />
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    loading: state?.reducers?.loading,
    userDetails: state?.reducers?.userDetails,
  };
};

const mapActionsToProps = (actions) => {
  return {};
};

export default connect(mapStateToProps, mapActionsToProps)(PaypalCredit);
