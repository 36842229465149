import React, { useState, useEffect } from "react";
import moment from "moment";
import { Helmet } from "react-helmet-async";
import { Pagination } from "../../../Utils";
import { GetCreditHistoryService } from "../../../Services/Api/comman";
import { AdminFooter } from "../../Layout";
import SearchIcon from "@mui/icons-material/Search";
import RestartAltIcon from "@mui/icons-material/RestartAlt";

const Credits = () => {
  const [page, setPage] = useState(0);
  const [limit, setLimit] = useState(10);
  const [totalRecords, setTotalRecords] = useState(0);
  const [isLoading, setIsLoading] = useState(true);
  const [historyList, setHistoryList] = useState([]);
  const [searchInputWord, setSearchInputWord] = useState(null);
  const [searchWord, setSearchWord] = useState(null);

  const GetCreditHistoryList = async (
    limit = "",
    page = "",
    searchText = null
  ) => {
    setIsLoading(true);
    await GetCreditHistoryService({
      limit: limit,
      page: page,
      searchText: searchText || null,
    })
      .then((res) => {
        setIsLoading(false);
        if (res?.data?.data?.data?.length) {
          setHistoryList(res?.data?.data?.data);
          setTotalRecords(res?.data?.data?.paginate?.total);
        } else {
          setHistoryList([]);
          setTotalRecords(0);
        }
      })
      .catch((err) => {
        setHistoryList([]);
        setTotalRecords(0);
        setIsLoading(false);
      });
  };

  const handlePageChange = async (e, pageNew) => {
    setPage(pageNew);
  };

  const handleLimitChange = async (event) => {
    setLimit(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleDetails = (history) => {
    let tdDetails = "";

    if (history?.is_transfered) {
      if (history?.action === 'credit') {
        tdDetails = (
          <>
            <span>Transfer From : </span>
            <br />
            {history?.transfer_from_user?.name || ""}{" "}
            {history?.transfer_from_user?.email || ""}
            <br />
            {history?.transfer_from_user?.remarks || ""}
          </>
        );
      } else {
        tdDetails = (
          <>
            <span>Transfer To : </span>
            <br />
            {history?.transfer_to_user?.name || ""}{" "}
            {history?.transfer_to_user?.email || ""}
            <br />
            {history?.transfer_from_user?.remarks || ""}
          </>
        );
      }
    } else {
      if (history?.action === "deduct") {
        if (history?.subscription_id) {
          tdDetails = (
            <>
              <span>Subscription : </span>
              <br />
              {history?.subscription_id || ""}
            </>
          );
        } else {
          tdDetails = (
            <>
              <span>Product : </span>
              <br />
              {history?.product?.name || ""}
            </>
          );
        }
      } else {
        tdDetails = (
          <>
            <span>User Paid</span>
            <br />
            Transaction ID: {history?.transaction_id || ""}
          </>
        );
      }
    }

    return tdDetails;
  };

  const handleAlphbetSearch = () => {
    if (searchInputWord?.length >= 1) {
      setSearchWord(searchInputWord);
    }
  };

  useEffect(() => {
    GetCreditHistoryList(limit, page, searchWord);
  }, [limit, page, searchWord]);

  return (
    <>
      <Helmet>
        <title>Credit History | PhrasePulse</title>
      </Helmet>
      <div className="page-wrapper">
        <div className="content">
          <div className="page-header">
            <div className="row align-items-center">
              <div className="col">
                <h3 className="page-title">Credit History</h3>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-sm-12 col-md-12 col-lg-12">
              <div className="card card-table">
                <div className="card-body">
                  <div className="search-filter mt-2">
                    <input
                      type="text"
                      className="form-control"
                      name="search"
                      placeholder="Search"
                      onChange={(e) => setSearchInputWord(e?.target?.value)}
                      value={searchInputWord || ""}
                    />
                    <button
                      className="btn btnstl btn-primary"
                      onClick={(e) => handleAlphbetSearch()}
                    >
                      <SearchIcon />
                    </button>
                    <button
                      className="btn btnstl btn-dark"
                      onClick={(e) => window.location.reload()}
                    >
                      <RestartAltIcon />
                    </button>
                  </div>
                  <div className="table-responsive custom-pagination">
                    <table className="table table-striped">
                      <thead className="thead-light">
                        <tr>
                          <th>Date</th>
                          <th>Action</th>
                          <th>Credit</th>
                          <th>User</th>
                          <th>Details</th>
                        </tr>
                      </thead>
                      <tbody>
                        {!isLoading && (
                          <>
                            {historyList.map((history, index) => (
                              <tr key={index}>
                                <td>
                                  {moment(history?.createdOn).format(
                                    "DD-MM-YYYY hh:mm A"
                                  ) || ""}
                                </td>
                                <td>{history?.action || ""}</td>
                                <td>{history?.credit || ""}</td>
                                <td>
                                  {`${history?.user?.name || ""} (${history?.user?.email || ""
                                    })`}
                                </td>
                                <td>{handleDetails(history)}</td>
                              </tr>
                            ))}
                            {historyList.length === 0 && (
                              <tr>
                                <td colSpan={5}>No Records Found.</td>
                              </tr>
                            )}
                          </>
                        )}
                        {isLoading && (
                          <tr>
                            <td align="center" colSpan={5}>
                              Loading...
                            </td>
                          </tr>
                        )}
                        <Pagination
                          totalItem={totalRecords}
                          itemsPerPage={limit}
                          page={page}
                          handleChangePage={handlePageChange}
                          handleChangeRowsPerPage={handleLimitChange}
                          colSpan={5}
                        />
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
            <AdminFooter />
          </div>
        </div>
      </div>
    </>
  );
};

export default Credits;
